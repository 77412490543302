/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 src/Game/Assets/Models/Manequin/manequin.glb -k -K -0 src/Game/Components/Models/Manequin.jsx 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations, useKeyboardControls } from '@react-three/drei'
import useFollowCam from '../../hooks/useFollowCamera'
import { useFrame } from '@react-three/fiber'
import useCharacterAnimation from './useCharacterAnimation'

export function Player(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('assets/models/manequin/manequin.glb')
  const { animations: idleAnimation } = useGLTF("assets/models/manequin/idle.glb");
  const { animations: runAnimation } = useGLTF("assets/models/manequin/run_forward.glb");
  const { animations: backwardAnimation } = useGLTF("assets/models/manequin/run_backward.glb");
  const { animations: sprintAnimation } = useGLTF("assets/models/manequin/sprint.glb");
  const { animations: leftAnimation } = useGLTF("assets/models/manequin/strafe_left.glb");
  const { animations: righttAnimation } = useGLTF("assets/models/manequin/strafe_right.glb");
  
  idleAnimation[1].name = 'idle'
  runAnimation[1].name = 'run'
  backwardAnimation[1].name = 'back'
  sprintAnimation[1].name = 'sprint'
  leftAnimation[1].name = 'left'
  righttAnimation[1].name = 'right'
  
  const { actions } = useAnimations([idleAnimation[1], runAnimation[1], backwardAnimation[1], sprintAnimation[1], leftAnimation[1], righttAnimation[1]], group)
  const { animatePlayer } = useCharacterAnimation({group, actions})
  
  useEffect(() => {
    if(actions['idle'])
      actions['idle'].reset().play();
  }, [actions])

  useFrame(() => {
    animatePlayer();
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="PlayerCharacter">
        <group name="RootNode">
          <primitive object={nodes.wwwpoly3dnet___Hips} />
          <skinnedMesh name="BodyMesh" geometry={nodes.BodyMesh.geometry} material={materials.lambert2} skeleton={nodes.BodyMesh.skeleton} position={[-0.004, -0.098, 0.06]} rotation={[0, 0.809, -0.044]} scale={0.889} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('assets/models/manequin/manequin.glb')
